import React, { useState } from 'react';
import { useSearchUrls } from '~/hooks/useSearchUrls';
import accentByParentTheme, { ESGThemes } from '~/components/themeAccents';

interface ComponentProps {
    themeName: string;
    parentTheme: ESGThemes;
    className?: string;
    disableLink?: boolean;
}

const ThemePill = (props: ComponentProps): JSX.Element => {
    const { generateSearchByThemeUrl } = useSearchUrls();
    const [accentWidth, setAccentWidth] = useState<string>('w-4');
    const { themeName, parentTheme, className, disableLink } = props;

    const styles = `px-2 py-1 font-primary tracking-wide font-size-16 font-semibold text-black hover:text-black background-offset rounded-lg text-center flex flex-col ${
        className?.length ? className : ''
    }`;

    if (disableLink) {
        return (
            <span
                className={styles}
                onMouseOver={() => setAccentWidth('w-full')}
                onMouseOut={() => setAccentWidth('w-4')}
            >
                {themeName}
                <span
                    className={`border-b-2 ${accentByParentTheme(
                        parentTheme,
                        false,
                        true,
                    )} ${accentWidth} -mt-1 transition-all duration-500 ease-in-out`}
                ></span>
            </span>
        );
    } else {
        return (
            <a
                className={styles}
                href={generateSearchByThemeUrl(themeName)}
                onMouseOver={() => setAccentWidth('w-full')}
                onMouseOut={() => setAccentWidth('w-4')}
            >
                {themeName}
                <span
                    className={`border-b-2 ${accentByParentTheme(
                        parentTheme,
                        false,
                        true,
                    )} ${accentWidth} -mt-1 transition-all duration-500 ease-in-out`}
                ></span>
            </a>
        );
    }
};

export default ThemePill;
