import React from 'react';
import ApiClient, { showNotificationAfterApiCall } from '~/utils/apiClient';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import EmbedLink from './embedLink';

interface ComponentProps {
    contentId: number;
    contentUuid: string;
    companyId?: number;
    documentId?: number;
    documentPageId?: number;
}

const AdminDropdown = (props: ComponentProps): JSX.Element => {
    const { contentId, contentUuid, companyId, documentId, documentPageId } = props;

    const sendReindexRequest = async () => {
        const response = await ApiClient.get(`/api/admin/reindex-content/${props.contentId}`);
        showNotificationAfterApiCall(response);
    };

    const sendRetagRequest = async () => {
        const response = await ApiClient.get(`/api/admin/retag-content/${props.contentId}`);
        showNotificationAfterApiCall(response);
    };

    const updateContent = async (payload: any) => {
        const response = await ApiClient.update(`/api/admin/content/${contentId}`, { verification: payload });
        showNotificationAfterApiCall(response);
    };

    return (
        <DropdownMenu>
            <DropdownMenuTrigger>
                <i className="fas fa-screwdriver-wrench fa-fw xl:ml-0"></i>
                <p className="text-sm text-center 2xl:text-base xl:text-left xl:inline xl:ml-1 xl:mr-6">Edit</p>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
                <DropdownMenuLabel className="bg-gray-100 rounded font-bold">Links</DropdownMenuLabel>
                <DropdownMenuGroup>
                    <DropdownMenuItem>
                        <a href={`/admin/contents/${contentId}`} target="_blank" rel="noreferrer" className="w-full">
                            <i className="far fa-user-lock mr-2 h-4 w-4 text-gray-500" />
                            <span>View Admin</span>
                        </a>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                        <a
                            href={`/admin/contents/${contentId}/edit`}
                            target="_blank"
                            rel="noreferrer"
                            className="w-full"
                        >
                            <i className="far fa-screwdriver-wrench mr-2 h-4 w-4 text-gray-500" />
                            <span>Edit</span>
                        </a>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                        <a
                            href={`/internal/verify?uuid=${contentUuid}`}
                            target="_blank"
                            rel="noreferrer"
                            className="w-full"
                        >
                            <i className="far fa-stars mr-2 h-4 w-4 text-gray-500" />
                            <span>Open in Verify</span>
                        </a>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                        <EmbedLink contentId={contentUuid} onlyCopy>
                            <i className="fas fa-link mr-2 h-4 w-4 text-gray-500"></i>
                            <span>Copy Embed URL</span>
                        </EmbedLink>
                    </DropdownMenuItem>
                    {companyId ? (
                        <DropdownMenuItem>
                            <a
                                href={`/internal/companies/${companyId}`}
                                target="_blank"
                                rel="noreferrer"
                                className="w-full"
                            >
                                <i className="far fa-building mr-2 h-4 w-4 text-gray-500" />
                                <span>View Company</span>
                            </a>
                        </DropdownMenuItem>
                    ) : null}
                    {documentId ? (
                        <DropdownMenuItem>
                            <a
                                href={`/internal/document/${documentId}`}
                                target="_blank"
                                rel="noreferrer"
                                className="w-full"
                            >
                                <i className="far fa-book mr-2 h-4 w-4 text-gray-500" />
                                <span>View Document</span>
                            </a>
                        </DropdownMenuItem>
                    ) : null}
                    {documentPageId ? (
                        <DropdownMenuItem>
                            <a
                                href={`/admin/document_pages/${documentPageId}`}
                                target="_blank"
                                rel="noreferrer"
                                className="w-full"
                            >
                                <i className="far fa-file mr-2 h-4 w-4 text-gray-500" />
                                <span>Edit Document Page</span>
                            </a>
                        </DropdownMenuItem>
                    ) : null}
                </DropdownMenuGroup>
                <DropdownMenuLabel className="bg-gray-100 rounded font-bold mt-1">Actions</DropdownMenuLabel>
                <DropdownMenuGroup>
                    <DropdownMenuItem onClick={() => updateContent({ reviewed: true, verified: false })}>
                        <i className="far fa-ban mr-2 h-4 w-4 text-gray-500" />
                        <span>Unverify</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => updateContent({ reviewed: true, verified: true })}>
                        <i className="far fa-thumbs-up mr-2 h-4 w-4 text-gray-500" />
                        <span>Verify</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => sendReindexRequest()}>
                        <i className="far fa-paperclip mr-2 h-4 w-4 text-gray-500" />
                        <span>Reindex</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => sendRetagRequest()}>
                        <i className="far fa-eyes mr-2 h-4 w-4 text-gray-500" />
                        <span>Ask Clair nicely to re-theme</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => updateContent({ hidden: true, reviewed: true, verified: false })}>
                        <i className="far fa-thumbs-down mr-2 h-4 w-4 text-gray-500" />
                        <span>Delete/hide</span>
                    </DropdownMenuItem>
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

export default AdminDropdown;
