import React, { useState } from 'react';
import ApiClient, { showNotificationAfterApiCall } from '~/utils/apiClient';
import Spinner from '~/components/spinner';
import downloadBlob from '~/utils/downloadBlob';

interface ComponentProps {
    contentId: string;
    children: React.ReactNode;
}

const ExportContent = (props: ComponentProps): JSX.Element => {
    const { contentId, children } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const downloadZippedContent = async (): Promise<void> => {
        setIsLoading(true);
        const response = await ApiClient.export(`/api/content/${contentId}/export`, {});
        showNotificationAfterApiCall(response, 'Success! Your content export started downloading');
        if (!response?.error) {
            downloadBlob(response, `cometrics-${contentId}-${Date.now()}.zip`);
        }
        setIsLoading(false);
    };

    return (
        <div
            onClick={() => {
                if (!isLoading) {
                    downloadZippedContent();
                }
            }}
            className={`${isLoading ? 'disabled opacity-50' : ''} flex flex-row justify-between items-center`}
        >
            {children}
            {isLoading ? (
                <span>
                    {' '}
                    <Spinner className={'justify-end'} height={20} width={20} />
                </span>
            ) : null}
        </div>
    );
};

export default ExportContent;
