import qs from 'qs';

const createURL = (state: string): string => `?${qs.stringify(state)}`;

const searchStateToUrl = (searchState: string): string =>
    searchState ? `${window.location.pathname}${createURL(searchState)}` : '';

const urlToSearchState = ({ search }: { search: string }) => qs.parse(search.slice(1));

const generateSearchByThemeUrl = (theme: string, indexName: string): string => {
    return `/app/search?${indexName}%5BrefinementList%5D%5Bthemes.name%5D%5B0%5D=${encodeURIComponent(theme)}`;
};

const generateSearchByThemeAndCompanyUrl = (theme: string, company: string, indexName: string): string => {
    return `/app/search?${indexName}%5BrefinementList%5D%5Bthemes.name%5D%5B0%5D=${encodeURIComponent(
        theme,
    )}&${indexName}%5BrefinementList%5D%5Bcompany.name%5D%5B0%5D=${encodeURIComponent(company)}`;
};

const generateSearchByCompanyUrl = (company: string, indexName: string): string => {
    return `/app/search?${indexName}%5BrefinementList%5D%5Bcompany.name%5D%5B0%5D=${encodeURIComponent(company)}`;
};

const generateSearchFilteredByAds = (indexName: string): string => {
    return `/app/search?${indexName}%5Btoggle%5D%5Bis_ad%5D=true`;
};

const generateSearchByExecutiveUrl = (executiveName: string, indexName: string): string => {
    return `/app/search?${indexName}%5BrefinementList%5D%5Bexecutive_name%5D%5B0%5D=${encodeURIComponent(
        executiveName,
    )}`;
};

const generateSearchByExecutiveAndThemeUrl = (executiveName: string, themeName: string, indexName: string): string => {
    return `/app/search?${indexName}%5BrefinementList%5D%5Bexecutive_name%5D%5B0%5D=${encodeURIComponent(
        executiveName,
    )}&${indexName}%5BrefinementList%5D%5Bthemes.name%5D%5B0%5D=${encodeURIComponent(themeName)}`;
};

export {
    searchStateToUrl,
    createURL,
    urlToSearchState,
    generateSearchByThemeUrl,
    generateSearchByThemeAndCompanyUrl,
    generateSearchByCompanyUrl,
    generateSearchFilteredByAds,
    generateSearchByExecutiveUrl,
    generateSearchByExecutiveAndThemeUrl,
};
