import React from 'react';
import Spinner from '~/components/spinner';
import { useStats, UseStatsProps } from './useStats';

const Stats = (props: UseStatsProps): JSX.Element => {
    const { nbHits, areHitsSorted, nbSortedHits, processingTimeMS } = useStats(props);
    if (processingTimeMS > 0) {
        return areHitsSorted && nbHits !== nbSortedHits ? (
            <>
                <p className="pb-5 font-semibold tracking-wide font-primary text-base lg:text-lg xl:text-xl">{`${nbSortedHits!.toLocaleString()} relevant results sorted out of ${nbHits.toLocaleString()}`}</p>
            </>
        ) : (
            <>
                <p className="pb-5 font-semibold tracking-wide font-primary text-base lg:text-lg xl:text-xl">{`${
                    props.title ? props.title + ' - ' : ''
                }${nbHits.toLocaleString()} Results`}</p>
            </>
        );
    } else {
        return <Spinner />;
    }
};

export default Stats;
