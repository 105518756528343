import React, { useState, useEffect } from 'react';
import { ContainerWrapper } from '../layout/container';
import SaveGroupModal from './saveGroupModal';
import SaveGroupCard from './saveGroupCard';
import Api from '~/utils/apiClient';
import Spinner from '~/components/spinner';
import useQuery from '~/hooks/useQuery';
import useDocumentTitle from '~/hooks/useDocumentTitle';

export const EmptySaveGroups = (): JSX.Element => {
    return (
        <div className="flex flex-col">
            <h2 className="mb-3 text-2xl font-semibold text-black font-primary">No Groups Yet</h2>
            <p className="text-lg text-black font-primary">
                Organize your saved content into groups. Create a group for specific themes or use a single group for
                all your content.
            </p>
        </div>
    );
};

export interface SaveGroup {
    id: number;
    name: string;
    item_count: number;
    description?: string;
    has_content?: boolean;
    content_ids?: string[];
}

const SaveGroups = (): JSX.Element => {
    useDocumentTitle('Saved Content');
    const query = useQuery();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [saveGroups, setSaveGroups] = useState<SaveGroup[]>();

    const getSaveGroups = async () => {
        const response = await Api.get('/api/save-groups');
        setSaveGroups(response);
        setIsLoading(false);
    };

    useEffect(() => {
        getSaveGroups();
    }, []);

    return (
        <ContainerWrapper className="px-3 background-offset xl:px-0">
            <div className="container flex flex-col mx-auto mt-10">
                <header className="flex flex-row items-center justify-between mb-10">
                    <h1 className="text-3xl font-semibold text-black font-primary">Saved</h1>
                    <SaveGroupModal onClose={() => getSaveGroups()} openModal={!!query.get('newGroup')}>
                        <div className="text-base font-semibold btn-primary">
                            <i className="mr-1 fas fa-plus"></i> New Group
                        </div>
                    </SaveGroupModal>
                </header>
                {!isLoading && saveGroups && saveGroups.length > 0 && (
                    <div className="grid grid-flow-row grid-cols-1 gap-10 mb-10 xl:grid-cols-3 auto-rows-max">
                        {saveGroups.map((saveGroup: SaveGroup) => {
                            return (
                                <div key={saveGroup.id} className="w-full">
                                    <SaveGroupCard
                                        key={saveGroup.id}
                                        id={saveGroup.id}
                                        name={saveGroup.name}
                                        description={saveGroup.description}
                                        itemCount={saveGroup.item_count}
                                        onRemove={() => getSaveGroups()}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}

                {isLoading && <Spinner />}
                {!isLoading && saveGroups && saveGroups.length === 0 && (
                    <div className="w-full xl:w-1/3">
                        <div className="flex flex-col px-6 py-4 bg-white rounded-xl">
                            <EmptySaveGroups />
                        </div>
                    </div>
                )}
            </div>
        </ContainerWrapper>
    );
};

export default SaveGroups;
