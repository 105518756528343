import React from 'react';
import { Link } from 'react-router-dom';
import DeleteSaveGroup from './deleteSaveGroup';
import SaveGroupModal from './saveGroupModal';

interface ComponentProps {
    id: number;
    name: string;
    description?: string;
    itemCount: number;
    onRemove: () => void;
}

const SaveGroupCard = (props: ComponentProps): JSX.Element => {
    const { id, name, description, itemCount, onRemove } = props;

    return (
        <div className="rounded-xl py-4 px-6 bg-white flex flex-col h-full">
            <Link to={`/app/saved/${id}`} className="font-primary text-black grow font-semibold hover:text-verdant">
                <p className="text-xl font-bold mb-3">{name}</p>
                <p className="text-base text-vSubtle">{description}</p>
            </Link>

            <div className="flex flex-row justify-between items-center w-full pt-2 mt-5 text-vSubtle text-opacity-60 font-primary font-semibold tracking-wide font-size-16">
                <p>
                    {itemCount} {itemCount === 1 ? 'Item' : 'Items'}
                </p>
                <div className="flex items-center">
                    <SaveGroupModal onClose={() => onRemove()} saveGroup={{ id, name, description }}>
                        <div className="flex flex-row w-full xl:w-auto justify-end hover:text-verdant text-center xl:text-right cursor-pointer align-center items-center justify-items-center">
                            <i className="far fa-edit"></i>
                            <p className="text-sm xl:text-base text-center xl:text-right ml-1 mr-6">Edit</p>
                        </div>
                    </SaveGroupModal>
                    <div className="flex flex-row w-full xl:w-auto justify-end hover:text-red-600 text-center xl:text-right cursor-pointer align-center items-center justify-items-center">
                        <DeleteSaveGroup id={id} name={name} onClose={onRemove || undefined}>
                            <i className="far fa-trash-can-xmark"></i>
                            <p className="text-sm xl:text-base text-center xl:text-right inline ml-1">Remove</p>
                        </DeleteSaveGroup>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SaveGroupCard;
