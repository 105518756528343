import React, { createContext, useContext, useState, useEffect } from 'react';
import Api from '~/utils/apiClient';
import { useAuth } from '~/hooks/useAuth';
const SavedContentContext = createContext<any>([]);

const refreshContentIds = async () => {
    const response = await Api.get('/api/saved-content/');
    if (!response?.error) {
        const contentIds: Set<string> = new Set(
            response.map((savedContent: any) => savedContent.content_id.toString()),
        );
        return Array.from(contentIds);
    } else {
        return [];
    }
};

const useSavedContent = () => {
    const [savedContentIds, setSavedContentIds] = useContext(SavedContentContext);

    const setContentIds = (contentIds: string[]) => {
        setSavedContentIds(contentIds);
    };

    return { savedContentIds: savedContentIds, setContentIds: setContentIds };
};

const SavedContentProvider = ({ children }: { children: any }): JSX.Element => {
    const { isAuthenticated } = useAuth();
    const [savedContentIds, setSavedContentIds] = useState<string[]>();

    const getSavedContentIds = async () => {
        const contentIds = await refreshContentIds();
        setSavedContentIds(contentIds);
    };

    useEffect(() => {
        if (isAuthenticated) {
            getSavedContentIds();
        }
    }, []);

    return (
        <SavedContentContext.Provider value={[savedContentIds, setSavedContentIds]}>
            {children}
        </SavedContentContext.Provider>
    );
};

export { SavedContentProvider, useSavedContent, refreshContentIds };
