import React from 'react';
import ApiClient from '~/utils/apiClient';
import AsyncSelect from 'react-select/async';
import type { SingleValue, MultiValue } from 'react-select';

interface ComponentProps {
    apiUrl: string;
    handleSelectedOption: any;
    searchModel?: string;
    className?: string;
    placeholder?: string;
    name?: string;
    labelKey?: string;
    rootDataKey?: string;
    multiSelect?: boolean;
    initialValues?: SelectOption[];
    initialIds?: number[];
}

interface SelectOption {
    label: string;
    key: string | number;
    value: string | number;
}

const RemoteSelect = (props: ComponentProps): JSX.Element => {
    const {
        apiUrl,
        handleSelectedOption,
        searchModel,
        className,
        placeholder,
        name,
        labelKey,
        rootDataKey,
        multiSelect,
        initialValues,
        initialIds,
    } = props;
    const defaultValues = initialValues || [];

    const loadOption = (inputEvent: string) => {
        return searchEndpoint(inputEvent);
    };

    const searchEndpoint = async (searchVal: string) => {
        const searchParams = new URLSearchParams();
        if (searchModel) {
            searchParams.append('model', searchModel);
        }
        if (searchVal) {
            searchParams.append('name', searchVal);
        }
        if (initialIds?.length) {
            searchParams.append('include_ids', initialIds.join(','));
        }

        return new Promise<any>(resolve => {
            ApiClient.get(`${apiUrl}?${searchParams.toString()}`).then(searchRes => {
                try {
                    if (rootDataKey) {
                        searchRes = searchRes[rootDataKey];
                    }
                    resolve(
                        searchRes.flatMap((item: any): SelectOption[] => {
                            if (item?.id) {
                                const option = {
                                    label: labelKey ? item[labelKey] : item.name,
                                    key: item.id,
                                    value: item.id,
                                };
                                if (initialIds?.includes(item.id)) {
                                    if (!defaultValues.find(val => val.key === item.id)) {
                                        defaultValues.push(option);
                                    }
                                }
                                return [option];
                            } else {
                                return [];
                            }
                        }),
                    );
                } catch (e) {
                    console.error(e);
                    resolve([]);
                }
            });
        });
    };

    return (
        <>
            <AsyncSelect
                loadOptions={loadOption}
                defaultValue={defaultValues}
                defaultOptions
                cacheOptions
                className={`multiselect-filter ${className}`}
                placeholder={placeholder ? placeholder : `Select an option`}
                onChange={selectedOption => {
                    if (multiSelect) {
                        handleSelectedOption((selectedOption as MultiValue<SelectOption>)?.map(option => option.value));
                    } else {
                        handleSelectedOption((selectedOption as SingleValue<SelectOption>)?.value);
                    }
                }}
                name={name}
                isMulti={multiSelect}
            />
        </>
    );
};

export default RemoteSelect;
