import React from 'react';
import Select from 'react-select';
import { useRefinementList, UseRefinementListProps } from 'react-instantsearch';
import { cn } from '~/utils/ui';

type ComponentProps = UseRefinementListProps & {
    placeholderText?: string;
    className?: string;
};

const MultiSelectFilter = (props: ComponentProps): JSX.Element => {
    const { placeholderText, className } = props;
    const { items, refine } = useRefinementList(props);

    const refinedItems = items
        ?.map((item: any) => {
            if (item.isRefined) {
                return { ...item, label: `${item.label}` };
            }
        })
        ?.filter((e: any) => e);

    const searchableItems = items
        ?.map((item: any) => {
            return { ...item, label: `${item.label}` };
        })
        ?.filter((e: any) => e);

    if (items.length && refinedItems !== undefined && searchableItems !== undefined) {
        return (
            <Select
                placeholder={placeholderText}
                value={refinedItems}
                isMulti
                name="colors"
                options={searchableItems}
                className={cn('multiselect-filter', className)}
                classNamePrefix="select"
                onChange={(itemArray, actionWithItem) => {
                    if (actionWithItem?.option) {
                        refine(actionWithItem.option.value);
                    }
                    for (const item of items) {
                        if (item.isRefined) {
                            if (!itemArray.some(i => `${item.label}` === i.label)) {
                                refine(item.value);
                            }
                        }
                    }
                }}
                noOptionsMessage={inputEvent => {
                    if (props.attribute === 'company.name' && inputEvent?.inputValue) {
                        return (
                            <>
                                <h1 className="mb-3">No results for {inputEvent.inputValue}</h1>
                                <p>
                                    If you&apos;d like to see content from {inputEvent.inputValue},{' '}
                                    <a href={`/app/nominate/companies`} className="text-verdant hover:underline">
                                        click here
                                    </a>{' '}
                                    to nominate the company.
                                </p>
                            </>
                        );
                    } else {
                        return <>No Results Found</>;
                    }
                }}
            />
        );
    } else {
        return (
            <Select
                placeholder="No Filter Options"
                isMulti
                name="colors"
                options={[]}
                className="basic-multi-select"
                classNamePrefix="select"
            />
        );
    }
};

export default MultiSelectFilter;
