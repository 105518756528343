import React from 'react';
import { useField } from 'formik';
import RemoteSelect from './remoteSelect';

interface ComponentProps {
    apiUrl: string;
    searchModel?: string;
    fieldProps: any;
    className?: string;
    placeholder?: string;
    multiSelect?: boolean;
    skipInitialValues?: boolean;
    labelKey?: string;
    rootDataKey?: string;
    setInitialIds?: boolean;
}

const FormikAsyncSelect = (props: ComponentProps): JSX.Element => {
    const {
        apiUrl,
        searchModel,
        className,
        placeholder,
        multiSelect,
        skipInitialValues,
        labelKey,
        rootDataKey,
        setInitialIds,
    } = props;
    const [field, meta, helpers] = useField(props.fieldProps);

    let initValues;
    let initIds;
    const { setValue } = helpers;

    if (!setInitialIds && !skipInitialValues && field.value?.length) {
        initValues = field.value.map((item: any) => {
            if (item.id && item.name) {
                return {
                    label: item.name,
                    key: item.id,
                    value: item.id,
                };
            }
        });
    }

    if (setInitialIds && Number.isInteger(field.value) && !Array.isArray(field.value)) {
        initIds = Array.from(new Set([field.value]));
    } else {
        if (field.value && Array.isArray(field.value)) {
            initIds = Array.from(new Set([...field.value]));
        }
    }

    return (
        <>
            <RemoteSelect
                apiUrl={apiUrl}
                handleSelectedOption={(option: string) => {
                    setValue(option);
                }}
                searchModel={searchModel}
                className={className}
                placeholder={placeholder}
                name={field.name}
                multiSelect={multiSelect}
                initialValues={skipInitialValues ? undefined : initValues}
                labelKey={labelKey}
                rootDataKey={rootDataKey}
                initialIds={setInitialIds && initIds?.length ? initIds : undefined}
            />
        </>
    );
};

export default FormikAsyncSelect;
