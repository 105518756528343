import React, { useState } from 'react';
import Modal from 'react-modal';

interface ComponentProps {
    children: any;
    id: number;
    name: string;
    onConfirmation: (id: any) => void;
    title: string;
    yesText?: string;
    description?: string;
}

function DeleteWithConfirmation(props: ComponentProps): JSX.Element {
    const { children, onConfirmation } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const closeModal = () => {
        setIsOpen(false);
    };
    return (
        <div>
            <div onClick={() => setIsOpen(true)}>{children}</div>
            {isOpen ? (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    contentLabel={props.title}
                    className="cm-modal-body"
                    overlayClassName="cm-modal-overlay"
                    ariaHideApp={false}
                >
                    <button className="absolute right-5 text-2xl hover:opacity-80" onClick={closeModal}>
                        <i className="fal fa-times text-vSubtle"></i>
                    </button>
                    <h2 className="font-primary text-xl">{props.title}</h2>

                    <p className="font-primary text-lg mb-5">
                        {props.description?.length
                            ? props.description
                            : `Are you sure you want to delete {props.name}?`}
                    </p>
                    <div className="flex flex-row justify-between mt-20">
                        <button onClick={closeModal} className="btn-primary text-2xl !px-10">
                            No, cancel
                        </button>
                        <button onClick={() => onConfirmation(props.id)} className="btn-danger-outline">
                            {props.yesText ? props.yesText : 'Yes, delete'}
                        </button>
                    </div>
                </Modal>
            ) : (
                <></>
            )}
        </div>
    );
}

export default DeleteWithConfirmation;
