import React, { useState } from 'react';
import Modal from 'react-modal';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import Api from '~/utils/apiClient';
import { toast } from 'react-toastify';

interface ComponentProps {
    children: any;
    onClose?: () => void;
    openModal?: boolean;
    saveGroup?: any;
}

interface FormInputs {
    name: string;
    description: string;
}

const SaveGroupModal = (props: ComponentProps): JSX.Element => {
    const { children, onClose, openModal, saveGroup } = props;
    const [isOpen, setIsOpen] = useState<boolean>(openModal || false);

    const closeModal = () => {
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    };

    return (
        <div>
            <div onClick={() => setIsOpen(true)}>{children}</div>
            {isOpen ? (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    contentLabel="New Save Group"
                    className="cm-modal-body m-w-600"
                    overlayClassName="cm-modal-overlay"
                    ariaHideApp={false}
                >
                    <button className="absolute right-5 text-2xl hover:opacity-80" onClick={closeModal}>
                        <i className="fal fa-times text-vSubtle"></i>
                    </button>
                    <h2 className="font-primary text-2xl mb-5">Save Group</h2>

                    <Formik
                        initialValues={{
                            name: saveGroup?.name || '',
                            description: saveGroup?.description || '',
                        }}
                        onSubmit={async (values: FormInputs, { setSubmitting }: FormikHelpers<FormInputs>) => {
                            let response;
                            if (saveGroup?.id) {
                                response = await Api.update(`/api/save-groups/${saveGroup?.id}`, {
                                    save_group: { ...values },
                                });
                            } else {
                                response = await Api.create('/api/save-groups', {
                                    save_group: { ...values },
                                });
                            }

                            if (response && !response.error) {
                                closeModal();
                                toast.success(`${saveGroup?.id ? 'Group updated' : 'New group created'}`);
                                setSubmitting(false);
                            } else {
                                toast.error('There was a problem submitting your group');
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Field
                                    id="name"
                                    name="name"
                                    placeholder="Name"
                                    className="cm-input w-full text-xl p-2 mt-5"
                                />

                                <Field
                                    id="description"
                                    name="description"
                                    placeholder="Group Description"
                                    as="textarea"
                                    rows="10"
                                    className="cm-input w-full text-xl p-2 mt-5"
                                />
                                <button type="submit" className="btn-primary text-xl mt-5" disabled={isSubmitting}>
                                    {saveGroup?.id ? <>Update Group</> : <>Create Group</>}
                                </button>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            ) : (
                <></>
            )}
        </div>
    );
};

export default SaveGroupModal;
