import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Spinner from '~/components/spinner';
import { useRefinementList, UseRefinementListProps } from 'react-instantsearch';
import ApiClient from '~/utils/apiClient';

type ComponentProps = UseRefinementListProps & {
    children: JSX.Element;
};

const CompanyBrowser = (props: ComponentProps) => {
    const { items, refine } = useRefinementList(props);
    const { children } = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [companies, setCompanies] = useState<Record<string, any>[]>();

    const getCompanies = async () => {
        const companyResponse = await ApiClient.get('/api/facets/company');
        if (companyResponse?.length && !companyResponse.error) {
            setCompanies(companyResponse);
        }
    };

    useEffect(() => {
        getCompanies();
    }, []);

    const closeModal = (): void => {
        setIsOpen(false);
    };
    return (
        <>
            <div onClick={() => setIsOpen(true)}>{children}</div>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                contentLabel="Modal"
                className="cm-modal-body xl:w-2/3 max-h-full mt-10 xl:mt-0 overflow-scroll xl:overflow-auto"
                overlayClassName="cm-modal-overlay"
                ariaHideApp={false}
                preventScroll={true}
            >
                <button className="absolute right-5 text-2xl hover:opacity-80" onClick={closeModal}>
                    <i className="fal fa-times text-vSubtle"></i>
                </button>

                <h2 className="font-primary font-bold text-2xl">Browse Companies</h2>
                <p className="font-primary font-semibold text-lg">Select as many as you&apos;d like</p>
                <div className="flex border-b border-gray-500 my-5"></div>
                {companies?.length && items ? (
                    <ul className="h-80 xl:h-96 overflow-y-scroll border-b-2 border-gray-200 grid xl:grid-cols-3">
                        {companies.map(company => {
                            if (company?.name?.length) {
                                return (
                                    <li
                                        key={company.id}
                                        className={`font-primary font-semibold text-lg py-1 pl-2 hover:text-white hover:bg-verdant cursor-pointer ${
                                            items.filter(item => item.label === company.name)?.[0]?.isRefined
                                                ? 'bg-verdant text-white'
                                                : ''
                                        }`}
                                        onClick={() => {
                                            refine(company.name);
                                        }}
                                    >
                                        {company.name}
                                    </li>
                                );
                            }
                        })}
                    </ul>
                ) : (
                    <Spinner />
                )}
                <div className="flex flex-row justify-between items-center mt-10">
                    <button type="submit" className={`btn-primary text-lg`} onClick={() => closeModal()}>
                        Save &amp; Close
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default CompanyBrowser;
