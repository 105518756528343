import React from 'react';
import { HitsPerPage, Pagination } from 'react-instantsearch';
import { useStats, UseStatsProps } from './useStats';

const SearchPagination = (props: UseStatsProps): JSX.Element => {
    const { nbHits, processingTimeMS } = useStats(props);
    if (nbHits && processingTimeMS) {
        return (
            <div className="flex flex-col xl:flex-row justify-between items-center mt-3">
                <HitsPerPage
                    items={[
                        { value: 5, label: '5 Per Page' },
                        { value: 10, label: '10 Per Page' },
                        { value: 25, label: '25 Per Page' },
                        { value: 50, label: '50 Per Page', default: true },
                    ]}
                />
                <div className="mt-3 xl:mt-0">
                    <Pagination padding={2} showFirst={false} />
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default SearchPagination;
