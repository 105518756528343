import React from 'react';
import { Link } from 'react-router-dom';

interface CompanyLinkProps {
    children: React.ReactNode;
    id: string;
}

const CompanyLink = ({ children, id }: CompanyLinkProps): JSX.Element => {
    return <Link to={`/app/company/${id}`}>{children}</Link>;
};

export default CompanyLink;
