const downloadBlob = (blobReponse: Blob, filename: string): void => {
    const blob = new Blob([blobReponse]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
};

export default downloadBlob;
