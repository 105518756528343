// Vendor Imports
import React from 'react';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import Linkify from 'linkify-react';
import { Link } from 'react-router-dom';

// Util Imports
import { useAuth } from '~/hooks/useAuth';
import { useSearchUrls } from '~/hooks/useSearchUrls';
import truncate from '~/utils/truncate';

// Layout Imports
import PlatformIcon from '~/components/platformIcon';
import ReportContent from '../reportContent';
import ThemePill from './themePill';
import Save from '../saved/save';
import CompanyLink from '../company/components/companyLink';
import AdminDropdown from './components/adminDropdown';

const ResultCard = ({
    hit,
    hideFooter,
    hideBorder,
    truncateText,
}: {
    hit: any;
    hideFooter?: boolean;
    hideBorder?: boolean;
    truncateText?: boolean;
}): JSX.Element => {
    const { isAdmin } = useAuth();
    const { generateSearchFilteredByAds } = useSearchUrls();
    let assetUrl;
    const showMedia = hit?.showMedia;
    if (hit?.assets?.length) {
        const imageAssets = hit?.assets?.filter((asset: any) => asset.mime.startsWith('image'));
        assetUrl = imageAssets?.length ? imageAssets?.[0]?.thumbnail : hit.assets[0].thumbnail;
    } else {
        assetUrl = null;
    }

    return (
        <div className={`rounded-xl px-5 py-3 2xl:p-5 bg-white flex flex-col mb-5 2xl:mb-10`}>
            <div
                className={`flex flex-row justify-between items-center text-sm 2xl:text-base font-primary text-opacity-60 text-vSubtle font-semibold tracking-wider ${
                    hit?.platform?.length || hit.company?.logo_url?.length ? '2xl:pb-3 2xl:-mt-2' : ''
                }`}
            >
                <div className="flex flex-row justify-start xl:w-1/2">
                    {hit?.is_ad ? (
                        <a href={generateSearchFilteredByAds()} className="flex flex-row items-center">
                            <i className="mr-2 text-lg fa-light fa-rectangle-ad 2xl:text-xl xl:inline hover:opacity-80"></i>
                        </a>
                    ) : null}
                    {hit?.has_translation && hit?.translations?.length ? (
                        <Tippy content="Translated">
                            <div className="flex flex-row items-center">
                                <i className="mr-2 text-lg fa-light fa-language 2xl:text-xl xl:inline"></i>
                            </div>
                        </Tippy>
                    ) : null}
                    {hit?.publisher_created_at?.length && (
                        <Link to={`/app/content/${hit.uuid}`} className="hover:text-gray-400">
                            <span className="text-sm 2xl:text-base">
                                {moment(hit.publisher_created_at).format('LL')}
                            </span>
                        </Link>
                    )}
                </div>

                <div className={` ${showMedia ? '' : 'xl:w-1/2'} flex flex-row justify-end items-center`}>
                    {hit?.platform?.length ? (
                        <Tippy content={hit.platform}>
                            <div className="flex flex-row items-center ml-5">
                                <p className="hidden mr-3 xl:inline">{hit.platform}</p>
                                <PlatformIcon
                                    key="platformIcon"
                                    platform={hit.platform}
                                    className="text-lg 2xl:text-xl"
                                />
                            </div>
                        </Tippy>
                    ) : null}
                    {hit?.is_document && hit?.document?.uuid && hit?.document?.company_slug ? (
                        <Tippy content="View Document">
                            <div className="flex flex-row items-center ml-5">
                                <Link
                                    to={`/app/company/${hit.document.company_slug}/document/${hit.document.uuid}`}
                                    className="hover:text-gray-400"
                                >
                                    <i className="mr-3 text-lg fa-light fa-folder-open 2xl:text-xl xl:inline"></i>
                                </Link>
                            </div>
                        </Tippy>
                    ) : null}
                    {hit.executive?.name && hit.executive?.profile_thumbnail_url ? (
                        <Tippy content={hit.executive?.name}>
                            <div className="flex flex-row items-center ml-5">
                                <p className="hidden mr-3 xl:inline">Executive: </p>
                                <img
                                    src={hit.executive?.profile_thumbnail_url}
                                    className="h-8 w-8 rounded-full"
                                    alt=""
                                />
                            </div>
                        </Tippy>
                    ) : null}
                    {hit?.company?.name?.length && hit?.company?.id && hit?.company?.logo_url && (
                        <CompanyLink id={hit.company.slug || hit.company.id}>
                            <Tippy content={hit?.company.name}>
                                <div className="flex flex-row items-center ml-5">
                                    <p className="hidden mr-3 xl:inline">Mentioned: </p>
                                    <img
                                        src={hit.company.logo_url}
                                        className="w-auto max-h-8"
                                        style={{ maxWidth: 75 }}
                                        alt=""
                                    />
                                </div>
                            </Tippy>
                        </CompanyLink>
                    )}
                </div>
            </div>

            <div
                className={`flex flex-col ${showMedia ? '' : 'xl:flex-row'}  justify-between w-full ${
                    hideBorder ? '' : 'border-b border-gray-200'
                }`}
            >
                {assetUrl?.length && (
                    <div className={`${showMedia ? '' : 'xl:mr-2 xl:w-2/12 xl:min-w-md'}`}>
                        <Link
                            to={`/app/content/${hit.uuid}`}
                            className="flex items-center justify-center xl:max-w-full"
                        >
                            <img alt={'Cometrics Content Result Image'} src={assetUrl} className="max-w-full mx-auto" />
                        </Link>
                    </div>
                )}
                <div
                    className={` ${showMedia ? 'hidden' : ''} text-left pb-1 2xl:pb-3 text-black linkify ${
                        assetUrl?.length ? 'xl:w-10/12' : 'xl:w-full'
                    }`}
                >
                    <Linkify
                        tagName="h1"
                        className="text-base font-semibold break-words font-primary 2xl:text-lg linkify"
                        options={{
                            target: '_blank',
                        }}
                    >
                        {truncateText ? <span>{truncate(hit.title, 150)}</span> : <span>{hit.title}</span>}
                    </Linkify>
                    <Linkify
                        tagName="p"
                        className="text-sm break-words font-primary linkify 2xl:text-base"
                        options={{ target: '_blank' }}
                    >
                        {truncateText ? <span>{truncate(hit.description, 150)}</span> : <span>{hit.description}</span>}
                    </Linkify>
                </div>
            </div>
            {!hideFooter ? (
                <div className="flex flex-row justify-start w-full pt-3 text-sm font-semibold tracking-wide 2xl:pt-5 text-opacity-60 font-primary 2xl:text-base text-vSubtle">
                    <div
                        className={` ${
                            showMedia ? ' ' : 'xl:flex-row xl:justify-between '
                        }flex flex-col  justify-center  items-center w-full`}
                    >
                        <div className="flex flex-wrap items-center -mt-3 text-left xl:mr-3 xl:w-1/2 2xl:w-2/3">
                            {hit?.themes?.length &&
                                hit.themes.map((theme: any) => {
                                    if (theme) {
                                        return (
                                            <ThemePill
                                                key={theme.id}
                                                themeName={theme.name}
                                                parentTheme={theme.parent_name}
                                                className="mt-3 mr-2 whitespace-nowrap"
                                            />
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                        </div>
                        <div className="flex flex-row justify-between w-full mt-5 text-sm xl:w-auto xl:justify-end 2xl:text-base xl:mt-0">
                            {isAdmin ? (
                                <AdminDropdown
                                    contentId={hit.id}
                                    contentUuid={hit.uuid}
                                    documentId={hit?.document?.id}
                                    documentPageId={hit?.document_page?.id}
                                    companyId={hit?.company?.id}
                                />
                            ) : null}

                            <Save contentId={hit.id} name={hit.title}>
                                <i className="fas fa-heart fa-fw"></i>
                                <p className="text-sm 2xl:text-base text-center xl:text-left xl:inline xl:ml-1 xl:mr-6">
                                    Save
                                </p>
                            </Save>
                            <Link to={`/app/content/${hit.uuid}`} className="hover:text-gray-400">
                                <div className="text-center hover:text-gray-400">
                                    <i className="fas fa-list-ul fa-fw xl:ml-0"></i>
                                    <p className="text-sm text-center 2xl:text-base xl:text-left xl:inline xl:ml-1 xl:mr-6">
                                        Details
                                    </p>
                                </div>
                            </Link>
                            {hit.original_url && (
                                <a
                                    href={hit.original_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-center hover:text-gray-400"
                                >
                                    <i className="fas fa-external-link fa-fw xl:ml-0"></i>
                                    <p className="text-sm text-center 2xl:text-base xl:text-left xl:inline xl:ml-1 xl:mr-6">
                                        View
                                    </p>
                                </a>
                            )}
                            <ReportContent contentId={hit.uuid}>
                                <div className="text-center cursor-pointer hover:text-gray-400">
                                    <i className="fas fa-exclamation-triangle fa-fw xl:ml-0"></i>
                                    <p className="ml-1 text-sm text-center 2xl:text-base xl:text-left xl:inline">
                                        Report
                                    </p>
                                </div>
                            </ReportContent>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default ResultCard;
