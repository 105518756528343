import React, { useRef, useEffect } from 'react';

const DEFAULT_SUFFIX = '- Cometrics';

function useDocumentTitle(title: string, suffix = DEFAULT_SUFFIX) {
    const originalTitle = useRef(document.title);
    const titleWithSuffix = title ? `${title} ${suffix}` : originalTitle.current;

    useEffect(() => {
        if (document.title !== titleWithSuffix) {
            document.title = titleWithSuffix;
        }
        return () => {
            document.title = originalTitle.current;
        };
    }, []);
}

export default useDocumentTitle;
