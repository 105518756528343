import React, { useEffect, useState } from 'react';
import { EmptySaveGroups, SaveGroup } from '.';
import Api from '~/utils/apiClient';
import Spinner from '~/components/spinner';
import { useSavedContent, refreshContentIds } from '../savedContentContext';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';

interface ComponentProps {
    contentId: string;
}

const SaveGroupSelector = (props: ComponentProps): JSX.Element => {
    const { contentId } = props;
    const { setContentIds } = useSavedContent();
    const [saveGroups, setSaveGroups] = useState<SaveGroup[]>();
    const [isLoadingById, setIsLoadingById] = useState<number>();

    const getGroups = async () => {
        const response = await Api.get(`/api/save-groups?content_id=${contentId}`);
        setSaveGroups(response);
        setIsLoadingById(undefined);
    };

    const toggleSavedContent = async (saveGroupId: number, isContentSaved: boolean) => {
        setIsLoadingById(saveGroupId);
        if (isContentSaved) {
            await Api.delete(`/api/save-groups/${saveGroupId}/saved/${contentId}`);
        } else {
            await Api.create(`/api/save-groups/${saveGroupId}/saved`, {
                saved_content: { content_id: contentId },
            });
        }
        getGroups();
        setContentIds(await refreshContentIds());
    };

    useEffect(() => {
        getGroups();
    }, []);

    return (
        <div>
            {saveGroups && saveGroups.length > 0 && (
                <div className="flex flex-col">
                    {saveGroups.map((group: SaveGroup) => (
                        <div
                            key={group.id}
                            className="flex flex-row px-5 mb-3 rounded-xl justify-between items-center w-full hover:bg-gray-100 cm-border cursor-pointer active:border-verdant"
                            onClick={() => toggleSavedContent(group.id, !!group.has_content)}
                        >
                            <div className="flex flex-col py-5">
                                <h3 className="font-primary font-semibold text-lg text-black">{group.name}</h3>
                                <p className="font-primary text-base text-black">{group.description}</p>
                            </div>
                            {group.has_content && isLoadingById != group.id && (
                                <div className="text-verdant text-3xl">
                                    <i className="fad fa-check-circle"></i>
                                </div>
                            )}
                            {isLoadingById && isLoadingById === group.id && (
                                <Loader type="TailSpin" color="#159A1F" height={30} width={30} />
                            )}
                        </div>
                    ))}
                </div>
            )}

            {saveGroups && saveGroups.length === 0 && (
                <>
                    <EmptySaveGroups />
                    <Link to="/app/saved?newGroup=true">
                        <button className="btn-primary text-xl mt-5">Create Group</button>
                    </Link>
                </>
            )}

            {!saveGroups && <Spinner />}
        </div>
    );
};

export default SaveGroupSelector;
