import React, { useState } from 'react';
import { useRange } from 'react-instantsearch';
import { DateRange } from 'react-date-range';
import { Formik, Field, Form } from 'formik';
import Modal from 'react-modal';
import useWindowSize from '~/hooks/useWindowSize';
import { format, getUnixTime, parseISO, sub } from 'date-fns';
import { RangeConnectorParams } from 'instantsearch.js/es/connectors/range/connectRange';

interface FormInputs {
    startDate?: Date | string;
    endDate?: Date | string;
}

const DateFilter = (props: RangeConnectorParams) => {
    const { start, range, canRefine, refine, sendEvent } = useRange(props);
    const initialStartDate = start?.[0] && Number.isFinite(start?.[0]) ? new Date(start[0] * 1000) : undefined;
    const initialEndDate = start?.[1] && Number.isFinite(start?.[1]) ? new Date(start[1] * 1000) : undefined;

    const [startDate, setStartDate] = useState<Date | undefined>(initialStartDate);
    const [endDate, setEndDate] = useState<Date | undefined>(initialEndDate);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);

    const { width } = useWindowSize();

    const changeDateSelection = (ranges: any) => {
        const startDateSelection = parseISO(ranges?.startDate);
        const startDateSelectionUnixTime = getUnixTime(startDateSelection);
        const endDateSelection = parseISO(ranges?.endDate);
        const endDateSelectionUnixTime = getUnixTime(endDateSelection);
        if (startDateSelection > endDateSelection) {
            setShowError(true);
            return null;
        }
        setStartDate(startDateSelection);
        setEndDate(endDateSelection);

        if (startDateSelection && endDateSelection) {
            if (endDateSelectionUnixTime <= startDateSelectionUnixTime) {
                return null;
            }

            if (
                range?.min &&
                range?.max &&
                startDateSelectionUnixTime > range?.min &&
                endDateSelectionUnixTime < range?.max
            ) {
                refine([startDateSelectionUnixTime, endDateSelectionUnixTime]);
            } else {
                if (range?.min && startDateSelectionUnixTime > range?.min) {
                    refine([startDateSelectionUnixTime, range.max]);
                }
                if (range?.max && endDateSelectionUnixTime < range?.max) {
                    refine([range.min, endDateSelectionUnixTime]);
                }
            }
        } else if (!startDateSelection && !endDateSelection) {
            setStartDate(undefined);
            setEndDate(undefined);
            refine([undefined, undefined]);
        }
        setShowError(false);
        closeModal();
    };

    const clearDateSelection = () => {
        setShowError(false);
        refine([undefined, undefined]);
        setStartDate(undefined);
        setEndDate(undefined);
    };

    const closeModal = () => {
        setIsOpen(false);
        setShowError(false);
    };

    return (
        <div>
            <div className="flex flex-row text-center date-range-input text-gray-500">
                <p className="grow cursor-pointer font-primary" onClick={() => setIsOpen(true)}>
                    {startDate ? format(startDate, 'MMM do, yyyy') : 'Start Date'}
                </p>
                <i
                    className="fa-light fa-arrow-right-long text-gray-400 cursor-pointer"
                    onClick={() => setIsOpen(true)}
                ></i>
                <p className="grow cursor-pointer font-primary" onClick={() => setIsOpen(true)}>
                    {endDate ? format(endDate, 'MMM do, yyyy') : 'End Date'}
                </p>
                <span
                    className={`cursor-pointer mr-3 ${startDate && endDate ? '' : 'invisible'}`}
                    onClick={() => clearDateSelection()}
                >
                    <i className={`fa-light fa-xmark text-gray-400`}></i>
                </span>
            </div>

            {isOpen ? (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    contentLabel="Date Range Filter"
                    className="cm-modal-body"
                    overlayClassName="cm-modal-overlay"
                    ariaHideApp={false}
                >
                    <h2 className="font-primary text-2xl mb-5">Content Published Date Range</h2>
                    <Formik
                        initialValues={{
                            startDate: startDate
                                ? format(startDate, 'yyyy-MM-dd')
                                : format(sub(new Date(), { days: 14 }), 'yyyy-MM-dd'),
                            endDate: endDate
                                ? format(endDate, 'yyyy-MM-dd')
                                : format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd'),
                        }}
                        onSubmit={(values: FormInputs) => {
                            changeDateSelection(values);
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <label htmlFor="startDate" className="font-primary text-lg text-black font-semibold">
                                    Start Date
                                </label>
                                <Field
                                    id="startDate"
                                    name="startDate"
                                    type="date"
                                    className="cm-input w-full text-base p-2 mb-4"
                                    required
                                />

                                <label htmlFor="endDate" className="font-primary text-lg text-black font-semibold">
                                    End Date
                                </label>
                                <Field
                                    id="endDate"
                                    name="endDate"
                                    type="date"
                                    className="cm-input w-full text-base p-2 mb-8"
                                    max={format(new Date(), 'yyyy-MM-dd')}
                                    required
                                />
                                {showError ? (
                                    <p className="text-lg text-error p-2 border-red-600 border rounded font-semibold text-red-500">
                                        The start date must be before the end date.
                                    </p>
                                ) : null}
                                <div className="flex flex-row justify-end mt-5">
                                    <div className="btn-primary-outline mr-3" onClick={() => closeModal()}>
                                        Close
                                    </div>
                                    <button className="btn-primary" type="submit">
                                        Apply Date Range
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            ) : null}
        </div>
    );
};

export default DateFilter;
