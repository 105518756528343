import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Spinner from '~/components/spinner';
import { useRefinementList, UseRefinementListProps } from 'react-instantsearch';
import ApiClient from '~/utils/apiClient';

interface Category {
    id: number;
    active: boolean;
    name: string;
    parent_id: number;
    visible: boolean;
}

type ComponentProps = UseRefinementListProps & {
    children: JSX.Element;
};

const ThemeBrowser = (props: ComponentProps) => {
    const {
        items,
        hasExhaustiveItems,
        createURL,
        refine,
        sendEvent,
        searchForItems,
        isFromSearch,
        canRefine,
        canToggleShowMore,
        isShowingMore,
        toggleShowMore,
    } = useRefinementList(props);
    const { children } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [categories, setCategories] = useState<Category[]>();
    const [categoryRoots, setCategoryRoots] = useState<Record<string, any>>({
        environmental: {},
        social: {},
        governance: {},
    });

    const getCategories = async () => {
        const categoryResponse = await ApiClient.get('/api/categories');
        if (categoryResponse?.length && !categoryResponse.error) {
            setCategoryRoots({
                ...categoryRoots,
                environmental: categoryResponse.find((category: Category) => category.name === 'Environmental'),
                social: categoryResponse.find((category: Category) => category.name === 'Social'),
                governance: categoryResponse.find((category: Category) => category.name === 'Governance'),
            });
            setCategories(categoryResponse);
        }
    };

    useEffect(() => {
        getCategories();
    }, []);

    const closeModal = (): void => {
        setIsOpen(false);
    };
    return (
        <>
            <div onClick={() => setIsOpen(true)}>{children}</div>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                contentLabel="Modal"
                className="cm-modal-body xl:w-4/5 max-h-full mt-10 xl:mt-0 overflow-scroll xl:overflow-auto"
                overlayClassName="cm-modal-overlay"
                ariaHideApp={false}
                preventScroll={true}
            >
                <button className="absolute right-5 text-2xl hover:opacity-80" onClick={closeModal}>
                    <i className="fal fa-times text-vSubtle"></i>
                </button>

                <h2 className="font-primary font-bold text-2xl">Browse Themes</h2>
                <p className="font-primary font-semibold text-lg">Select as many as you&apos;d like</p>
                <div className="flex border-b border-gray-500 my-5"></div>
                {categories && items && categoryRoots ? (
                    <div className="grid grid-cols-1 xl:grid-cols-3 gap-5">
                        {Object.entries(categoryRoots).map(([categoryKey, categoryObject]) => (
                            <div key={categoryKey}>
                                <h3 className="font-primary font-bold text-xl capitalize pl-2">{categoryKey}</h3>
                                <div className="flex border-b border-gray-300 my-3"></div>
                                <ul className="h-80 overflow-y-scroll border-b-2 border-gray-200">
                                    {categories
                                        ?.filter(category => category.parent_id === categoryObject.id)
                                        ?.map((category: Category) => {
                                            if (category?.name?.length) {
                                                return (
                                                    <li
                                                        key={category.id}
                                                        className={`font-primary font-semibold text-lg py-1 pl-2 hover:text-white hover:bg-verdant cursor-pointer ${
                                                            items.filter(item => item.label === category.name)?.[0]
                                                                ?.isRefined
                                                                ? 'bg-verdant text-white'
                                                                : ''
                                                        }`}
                                                        onClick={() => {
                                                            refine(category.name);
                                                        }}
                                                    >
                                                        {category.name}
                                                    </li>
                                                );
                                            }
                                        })}
                                </ul>
                            </div>
                        ))}
                    </div>
                ) : (
                    <Spinner />
                )}
                <div className="flex flex-row justify-between items-center mt-10">
                    <button type="submit" className={`btn-primary text-lg`} onClick={() => closeModal()}>
                        Save &amp; Close
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default ThemeBrowser;
