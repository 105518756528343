import React, { useState } from 'react';
import Modal from 'react-modal';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import Api from '~/utils/apiClient';
import { toast } from 'react-toastify';
import { useSavedContent, refreshContentIds } from '../savedContentContext';

interface ComponentProps {
    children: any;
    id: number;
    name: string;
    onClose?: () => void;
}

interface FormInputs {
    confirmation: string;
}

const DeleteSaveGroup = (props: ComponentProps): JSX.Element => {
    const { children, onClose } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { setContentIds } = useSavedContent();

    const closeModal = () => {
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    };

    return (
        <div>
            <div onClick={() => setIsOpen(true)}>{children}</div>
            {isOpen ? (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    contentLabel="Delete Group"
                    className="cm-modal-body"
                    overlayClassName="cm-modal-overlay"
                    ariaHideApp={false}
                >
                    <button className="absolute right-5 text-2xl hover:opacity-80" onClick={closeModal}>
                        <i className="fal fa-times text-vSubtle"></i>
                    </button>
                    <h2 className="font-primary text-2xl">Delete Group</h2>

                    <p className="font-primary text-xl mb-5">
                        Are you sure you want to delete {props.name}?
                        <br /> <br />
                        Please type <span className="italic font-bold">delete</span> to confirm.
                    </p>
                    <Formik
                        initialValues={{
                            confirmation: '',
                        }}
                        onSubmit={async (values: FormInputs, { setSubmitting }: FormikHelpers<FormInputs>) => {
                            if (values.confirmation === 'delete' || values.confirmation === 'delete ') {
                                const response = await Api.delete(`/api/save-groups/${props.id}`);
                                if (response && !response.error) {
                                    closeModal();
                                    toast.success('Group deleted');
                                    setSubmitting(false);
                                    setContentIds(await refreshContentIds());
                                } else {
                                    toast.error('There was a problem deleting your group');
                                    setSubmitting(false);
                                }
                            }
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Field
                                    id="confirmation"
                                    name="confirmation"
                                    placeholder=""
                                    className="cm-input w-full text-xl p-2"
                                />

                                <button
                                    type="submit"
                                    className="cursor-pointer bg-red-500 text-white px-3 py-2 hover:opacity-80 rounded-lg text-xl mt-5"
                                    disabled={isSubmitting}
                                >
                                    Delete Group
                                </button>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            ) : (
                <></>
            )}
        </div>
    );
};

export default DeleteSaveGroup;
