import ApiClient from '~/utils/apiClient';
import { InstantMeiliSearchInstance, instantMeiliSearch } from '@meilisearch/instant-meilisearch';

class Meili {
    apiKey: string;
    appId: string;
    meiliApiUrl: string;
    meiliApiKey: string;
    indexes: Record<string, string>;
    companyApiKey: string;
    userUuid: string;
    meiliAdminApiKey: string;

    constructor(
        apiKey?: string,
        appId?: string,
        meiliApiUrl?: string,
        meiliApiKey?: string,
        indexes?: Record<string, string>,
        companyApiKey?: string,
        userUuid?: string,
        meiliAdminApiKey?: string,
    ) {
        this.apiKey = apiKey || '';
        this.appId = appId || '';
        this.meiliApiUrl = meiliApiUrl || '';
        this.meiliApiKey = meiliApiKey || '';
        this.indexes = indexes || {};
        this.companyApiKey = companyApiKey || '';
        this.userUuid = userUuid || '';
        this.meiliAdminApiKey = meiliAdminApiKey || '';
    }

    async getCredentials(force?: boolean): Promise<void> {
        if (!this.indexes || !this.appId || !this.apiKey || !this.companyApiKey || !this.userUuid || force) {
            const { apiKey, appId, meiliApiUrl, meiliApiKey, indexes, uuid, companyApiKey, meiliAdminApiKey } =
                await ApiClient.get('/api/account/');
            this.indexes = indexes;
            this.appId = appId;
            this.apiKey = apiKey;
            this.meiliApiUrl = meiliApiUrl;
            this.meiliApiKey = meiliApiKey;
            this.companyApiKey = companyApiKey;
            this.meiliAdminApiKey = meiliAdminApiKey;
        }
    }

    get contentIndexName() {
        return this.indexes['content'];
    }

    get companyIndexName() {
        return this.indexes['company'];
    }

    async client({ admin = false }: { admin?: boolean } = {}): Promise<InstantMeiliSearchInstance> {
        await this.getCredentials();
        const apiKey = admin ? this.meiliAdminApiKey : this.apiKey;
        const { searchClient } = instantMeiliSearch(this.meiliApiUrl, apiKey, { finitePagination: true });
        return searchClient;
    }

    refreshCredentials(): void {
        this.getCredentials(true);
    }
}

export default new Meili();
export { Meili };
