import React, { useState } from 'react';
import Modal from 'react-modal';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import Api from '~/utils/apiClient';
import { toast } from 'react-toastify';

interface ComponentProps {
    children: any;
    contentId: number;
    disable?: boolean;
}

interface FormInputs {
    content_uuid: number;
    reason: string;
}

const ReportContent = (props: ComponentProps): JSX.Element => {
    const { children, contentId, disable } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <div>
            <div onClick={() => !disable && setIsOpen(true)}>{children}</div>

            {isOpen ? (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                    className="cm-modal-body"
                    overlayClassName="cm-modal-overlay"
                    ariaHideApp={false}
                >
                    <button className="absolute right-5 text-2xl hover:opacity-80" onClick={closeModal}>
                        <i className="fal fa-times text-vSubtle"></i>
                    </button>
                    <h2 className="font-primary text-2xl">Report this post for inaccuracy</h2>

                    <p className="font-primary text-xl py-5">
                        Describe the issue with this post. <br /> Be as detailed as possible so we can improve our
                        sorting and search engine.
                    </p>
                    <Formik
                        initialValues={{
                            content_uuid: contentId,
                            reason: '',
                        }}
                        onSubmit={async (values: FormInputs, { setSubmitting }: FormikHelpers<FormInputs>) => {
                            const response = await Api.create('/api/flagged-content', {
                                flagged_content: { ...values },
                            });
                            if (response && !response.error) {
                                closeModal();
                                toast.success('Thank you for your feedback!');
                                setSubmitting(false);
                            } else {
                                toast.error('There was a problem submitting your report.');
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Field
                                    id="reason"
                                    name="reason"
                                    placeholder=""
                                    as="textarea"
                                    rows="10"
                                    className="cm-input w-full text-xl p-2"
                                />
                                <button type="submit" className="btn-primary text-xl mt-5" disabled={isSubmitting}>
                                    Submit Report
                                </button>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            ) : (
                <></>
            )}
        </div>
    );
};

export default ReportContent;
