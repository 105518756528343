import React, { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

interface ComponentProps {
    children: any;
    contentId: string;
    onlyCopy?: boolean;
}

const EmbedLink = (props: ComponentProps): JSX.Element => {
    const { children, contentId, onlyCopy } = props;

    const embedUrl = `${window.location.origin}/embed/card/${contentId}`;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const closeModal = () => {
        setIsOpen(false);
    };

    const selectText = (event: any) => {
        if (event) {
            event?.target.select();
            copyToClipboard(event.target.value);
        }
    };

    const copyToClipboard = (url: any) => {
        try {
            if (navigator) {
                navigator.clipboard.writeText(url);
                toast.success('Copied URL to clipboard');
            }
        } catch (err) {
            toast.error('Something went wrong while coping URL to clipboard');
            console.error(err);
        }
    };

    return (
        <>
            <span
                onClick={() => {
                    if (onlyCopy) {
                        copyToClipboard(embedUrl);
                    } else {
                        setIsOpen(true);
                    }
                }}
            >
                {children}
            </span>
            {isOpen && !onlyCopy ? (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    contentLabel="Embed URL"
                    className="cm-modal-body"
                    overlayClassName="cm-modal-overlay"
                    ariaHideApp={false}
                >
                    <button className="absolute right-5 text-2xl hover:opacity-80" onClick={closeModal}>
                        <i className="fal fa-times text-vSubtle"></i>
                    </button>
                    <h2 className="font-primary text-2xl">Embed URL</h2>
                    <input
                        className="cm-input w-full text-lg 2xl:text-xl p-2 mt-5"
                        value={embedUrl}
                        onClick={selectText}
                    />
                </Modal>
            ) : (
                <></>
            )}
        </>
    );
};

export default EmbedLink;
