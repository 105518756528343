import React, { useState, useEffect, Fragment } from 'react';
import Modal from 'react-modal';
import { useSavedContent } from '../savedContentContext';
import SaveGroupSelector from './groupSelector';

interface ComponentProps {
    contentId: string;
    name: string;
    onClose?: () => void;
    disabled?: boolean;
    children: React.ReactNode;
}

const Save = (props: ComponentProps): JSX.Element => {
    const { contentId, onClose, disabled, children } = props;
    const { savedContentIds } = useSavedContent();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [contentSaved, setContentSaved] = useState<boolean>(false);
    const [playBounceAnimation, setPlayBounceAnimation] = useState<boolean>(false);

    const closeModal = () => {
        setIsOpen(false);
        if (contentSaved) {
            setPlayBounceAnimation(true);
        }
        if (onClose) {
            onClose();
        }
    };

    useEffect(() => {
        if (savedContentIds?.includes(contentId)) {
            setContentSaved(true);
        } else {
            setContentSaved(false);
        }
    }, [savedContentIds]);

    return (
        <>
            <div
                onClick={() => {
                    if (!disabled) setIsOpen(true);
                }}
                className={`text-center cursor-pointer ${
                    contentSaved ? 'text-verdant hover:text-opacity-80' : 'hover:text-gray-400'
                } ${playBounceAnimation ? 'animate-bounce-quick' : ''}`}
            >
                {children}
            </div>
            {isOpen ? (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    contentLabel="Save Content"
                    className="cm-modal-body m-w-600"
                    overlayClassName="cm-modal-overlay"
                    ariaHideApp={false}
                >
                    <button className="absolute right-5 text-2xl hover:opacity-80" onClick={closeModal}>
                        <i className="fal fa-times text-vSubtle"></i>
                    </button>
                    <h2 className="font-primary text-2xl mb-5">Save Content</h2>
                    <SaveGroupSelector contentId={contentId} />
                </Modal>
            ) : (
                <></>
            )}
        </>
    );
};

export default Save;
