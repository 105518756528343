import React from 'react';

interface ComponentProps {
    children: React.ReactNode;
    className?: string;
}

export const ContainerWrapper = (props: ComponentProps): JSX.Element => {
    const { children, className } = props;

    return (
        <main className={`flex flex-col xl:flex-row w-full h-full grow ${className?.length ? className : ''}`}>
            {children}
        </main>
    );
};

interface ContainerProps extends ComponentProps {
    noPadding?: boolean;
    fullWidth?: boolean;
}

export const Container = (props: ContainerProps): JSX.Element => {
    const { children, className, noPadding, fullWidth } = props;

    return (
        <section
            className={`w-full ${fullWidth ? 'xl:w-4/4' : 'xl:w-3/4'} background-offset ${
                noPadding ? '' : 'py-4 px-7 2xl:py-7 2xl:px-10'
            } ${className?.length ? className : ''}`}
        >
            {children}
        </section>
    );
};
