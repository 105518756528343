import React, { useState } from 'react';

interface ComponentProps {
    children: React.ReactNode;
    header?: string;
    expanded?: boolean;
}

const Sidebar = (props: ComponentProps): JSX.Element => {
    const { children, header, expanded } = props;
    const initiallyExpanded = expanded || false;
    const [isExpanded, setIsExpanded] = useState<boolean>(initiallyExpanded);

    return (
        <section className="flex flex-col w-full xl:w-1/4 py-3 lg:py-4 2xl:py-7 px-7 xl:min-h-full xl:h-full">
            <div
                className={`xl:hidden flex flex-row justify-between items-center cursor-pointer ${
                    isExpanded ? 'mb-3' : ''
                }`}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <h3 className="font-primary font-semibold text-lg text-left pb-1">{header}</h3>
                <div className={`${isExpanded ? '' : 'hidden'}`}>
                    <i className="fal fa-chevron-up"></i>
                </div>
                <div className={`${isExpanded ? 'hidden' : ''}`}>
                    <i className="fal fa-chevron-down"></i>
                </div>
            </div>
            <div className={`xl:hidden ${isExpanded ? '' : 'hidden'}`}>{children}</div>
            <div className="hidden xl:flex xl:flex-col">{children}</div>
        </section>
    );
};

export default Sidebar;
