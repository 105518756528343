import React, { useContext } from 'react';

import { AuthContext } from '../dashboard/authContext';

const useAuth = () => {
    const authContext = useContext(AuthContext);
    if (authContext === undefined) {
        throw Error('useAuth must be used within AuthProvider');
    }
    return authContext;
};

export { useAuth };
